import { triggerAPI, qs, qsa, getBox } from './util';
import { production, staging, dark } from './constants';
import { emitGA } from './marketing';
import { trackSegment } from './segment/segment-utils';

// Qr Illutration Handler script
const mainWrapper = qs('#qr-code');
const qrCodeForm = document.forms['payment-links-form'];
const submitBtn = qs('#submitBtn', mainWrapper);
const stepThree = qs('.three', mainWrapper);
const captchaErr = qs('.captcha-err-msg', mainWrapper);
const reCaptcha = qs('.g-recaptcha', mainWrapper);

let generatedToken = null;
let isCaptchaValidated = false;
let hasError = false;

if (innerWidth > 1200) {
  setTimeout(() => {
    qs('.top-banner-desc-image').classList.add('fadeOut');
    qs('.top-banner-desc-image').style.display = 'none';
    qs('.bar-code-scanner').classList.add('fadeIn');
    qs('.bar-code-scanner').style.display = 'flex';
  }, 4000);
}

function swapStage(currentStage, nextStage, hideElement, showElement = '') {
  currentStage.classList.remove('active');
  currentStage.classList.add('completed');
  nextStage && nextStage.classList.add('active');
  hideElement.style.display = 'none';
  showElement ? (showElement.style.display = 'block') : '';
  if (nextStage === stepThree) {
    qs('.step-three-signup', mainWrapper).style.display = 'block';
  }
}

function insertCatchSiteKey() {
  let key = null;
  if (location.host === 'razorpay.com') {
    key = production.reCaptchaKeyForReportMerchant;
  } else {
    key = staging.reCaptchaKeyForReportMerchant;
  }
  qs('.g-recaptcha', mainWrapper).setAttribute('data-sitekey', key);
}

function validateCaptcha(token) {
  isCaptchaValidated = true;
  captchaErr.style.display = 'none';
  generatedToken = token;
  hasError = false;
  validateSubmitBtn();
}

function handleExpiredCaptcha() {
  isCaptchaValidated = false;
  hasError = true;
  captchaErr.style.display = 'block';
  validateSubmitBtn();
}

function validateSubmitBtn() {
  if (isCaptchaValidated) {
    submitBtn.classList.remove('disabled');
    submitBtn.disabled = false;
  } else {
    submitBtn.classList.add('disabled');
    submitBtn.disabled = true;
  }
}

function getAPIURL() {
  if (location.host === 'razorpay.com') {
    return production.qrCodeGenerationPOSTAPI;
  } else {
    return staging.qrCodeGenerationPOSTAPI;
  }
}

function handleFormSubmit(e) {
  e.preventDefault();
  if (isCaptchaValidated) {
    if (!hasError) {
      let playload = {
        usage: 'mutiple_use',
        type: 'upi_qr',
        name: 'Razorpay',
        description:
          'this dummy playload, actual content will handled in frontend'
      };

      let qrDetails = {
        businessName: qrCodeForm.businessName.value,
        businessDesc: qrCodeForm.description.value
      };
      swapStage(
        qs('.two', mainWrapper),
        qs('.three', mainWrapper),
        qs('.initial-stage', mainWrapper)
      );
      //Store Email & pass email for signup
      window.sessionStorage.setItem('_pV', JSON.stringify(qrDetails));
      qs('.form-card').style.display = 'none';
      qs('.generated-link-card').style.display = 'flex';
      qs('.merchant.generation .head').innerHTML =
        qrCodeForm.businessName.value;
      qs('.merchant.generation .desc').innerHTML = qrCodeForm.description.value;
    }
  }
}

qs('.action-btn', mainWrapper).addEventListener('click', () => {
  swapStage(
    qs('.one', mainWrapper),
    qs('.two', mainWrapper),
    qs('.initial-stage', mainWrapper)
  );
  qs('.g-recaptcha', mainWrapper).style.display = 'block';
  emitGA('PL Demo', 'Try PL Demo', '');
});

qs('.download') &&
  qs('.download', mainWrapper).addEventListener('click', e => {
    e.preventDefault();
    swapStage(qs('.three', mainWrapper), '', qs('.initial-stage', mainWrapper));
  });

window.validateCaptcha = validateCaptcha;
window.handleExpiredCaptcha = handleExpiredCaptcha;

qrCodeForm.addEventListener('submit', handleFormSubmit);

insertCatchSiteKey();

// Scroll to action fold script

window.addEventListener('scroll', e => {
  const doc = document.documentElement;
  const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

  if (innerWidth > 1200) {
    let whyQrCode = qs('.qr-codes', mainWrapper);
    if (getBox(whyQrCode).top < 0) {
      whyQrCode.classList.add('sticky');
    } else if (getBox(whyQrCode).top > 0) {
      whyQrCode.classList.remove('active');
    }

    if (top > 2085 && top < 2316) {
      qs('.illustrations.one').classList.add('active');
      qs('.points .point.one').classList.add('active');

      qs('.illustrations.two').classList.remove('active');
      qs('.points .point.two').classList.remove('active');

      qs('.illustrations.three').classList.remove('active');
      qs('.point.three').classList.remove('active');
    } else if (top > 2316 && top < 2600) {
      qs('.illustrations.one').classList.remove('active');
      qs('.point.one').classList.remove('active');

      qs('.illustrations.two').classList.add('active');
      qs('.point.two').classList.add('active');

      qs('.illustrations.three').classList.remove('active');
      qs('.point.three').classList.remove('active');
    } else if (top > 2600 && top < 3000) {
      qs('.illustrations.one').classList.remove('active');
      qs('.point.one').classList.remove('active');

      qs('.illustrations.two').classList.remove('active');
      qs('.point.two').classList.remove('active');

      qs('.illustrations.three').classList.add('active');
      qs('.point.three').classList.add('active');
    }

    let collectQR = qs('.collect', mainWrapper);
    if (getBox(collectQR).top < 0) {
      collectQR.classList.add('sticky');
    } else if (getBox(collectQR).top > 0) {
      collectQR.classList.remove('active');
    }

    if (top > 3000 && top < 4500) {
      qsa('.instruction').forEach(node => {
        node.classList.add('show');
      });
    } else if (top < 3100) {
      qsa('.instruction').forEach(node => {
        node.classList.remove('show');
      });
    }
  }
});
