module.exports = {
  production: {
    paymentLinksDemoAPI: 'https://api.razorpay.com/v1/payment_links_demo',
    reportMerchantGETAPI:
      'https://api.razorpay.com/v1/customer_flagging/entity_details/',
    reportMerchantPOSTAPI:
      'https://api.razorpay.com/v1/customer_flagging/post_customer_grievance',
    sentryDSN:
      'https://8d75135d74c7401794ce118f8390636c@sentry9.razorpay.com/10',
    reCaptchaKeyForReportMerchant: '6LdsmwETAAAAADmNGCLvbrjL09O_Fv7WOVTngbO4',
    qrCodeGenerationPOSTAPI:
      'https://api.razorpay.com/v1/payments/qr_codes/demo'
  },
  staging: {
    reportMerchantGETAPI:
      'https://beta-api.stage.razorpay.in/v1/customer_flagging/entity_details/',
    reportMerchantPOSTAPI:
      'https://beta-api.stage.razorpay.in/v1/customer_flagging/post_customer_grievance',
    reCaptchaKeyForReportMerchant: '6Lf110caAAAAAAZGruoMxU2Dt89srEKuzUnhjl6-',
    qrCodeGenerationPOSTAPI: 'https://api.razorpay.in/v1/payments/qr_codes/demo'
  },
  dark: {
    reportMerchantGETAPI:
      'https://api-dark.razorpay.com/v1/customer_flagging/entity_details/',
    reportMerchantPOSTAPI:
      'https://api-dark.razorpay.com/v1/customer_flagging/post_customer_grievance',
    reCaptchaKeyForReportMerchant: '6Lf110caAAAAAAZGruoMxU2Dt89srEKuzUnhjl6-'
  },
  func: {
    reportMerchantGETAPI:
      'https://api-2.func.razorpay.in/v1/customer_flagging/entity_details/',
    reportMerchantPOSTAPI:
      'https://api-2.func.razorpay.in/v1/customer_flagging/post_customer_grievance/'
  }
};
